import { lazy, Suspense } from 'react'

const DashboardLazy = lazy(() => import('../components/Dashboard/Dashboard'))

const isSSR = typeof window === 'undefined'

const DashboardWrapper = () => {
  return (
    <>
      {isSSR ? (
        <p>Loading dashboard...</p>
      ) : (
        <Suspense fallback={<div />}>
          <DashboardLazy />
        </Suspense>
      )}
    </>
  )
}

export default DashboardWrapper
